import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import UserService from '@service/user-service/UserService';
import { ROLE } from '@utils/constants';
import {
  getAuthToken,
  getRole,
  getSideNavEndItems,
  getSideNavItems,
} from '@utils/Helper/helper';
import { ROUTES } from '@utils/routes';
import LoadingAnimation from '@atoms/LoadingAnimation';
import { matchPath } from "react-router";


export const END_USER_ACCESSIBLE_ROUTES = [
  ROUTES.HOME,
  ROUTES.MY_APPLICATIONS,
  ROUTES.SETTINGS,
  ROUTES.USER_ONBOARDING,
];

export const isUserAuthorized = (role: string, route: any): boolean => {
  if (['admin', 'superadmin'].includes(role?.toLowerCase())) {
    return true;
  } else {
    return END_USER_ACCESSIBLE_ROUTES.includes(route.pathname) || !!matchPath(route.pathname, { path: ROUTES.CONNECTION, exact: true });
  }
};

// A wrapper for <PrivateRoute> that redirects to the login
// screen if the user is not logged in.
const PrivateRouteComponent = ({ component: Component, token, ...rest }: any) => {
  const history = useHistory();

  console.log("%c TOKEN=", "color: blue; font-size: 1.2em;", JSON.stringify(token, null, 2));

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    getAuthToken(),
  );
  const [showBackground, setShowBackground] = useState(false);

  const { role, isSsoUser } = token;

  console.log(`%c ROLE=${role} SSO-USER=${isSsoUser}`, "color: blue; font-size: 1.2em;");


  const setSSOAuthenticated = async () => {
    if (isSsoUser) {
      console.log(`%c SSO USER SO GETTING SSO STATUS`, "color: blue; font-size: 1.2em;");

      const { completedSSO } = await UserService.getSSOStatus();
      if (!completedSSO) {
        setIsUserAuthenticated('');
        console.log(`%c COMLEPTED SSO=false`, "color: blue; font-size: 1.2em;");
      } else {
        console.log(`%c COMPLETED SSO=true`, "color: blue; font-size: 1.2em;");
      }
    } else {
      console.log(`%c NOT AN SSO USER SO GETTING TOTP STATUS`, "color: blue; font-size: 1.2em;");
      if (getAuthToken()) {
        const isTotpVerified = await UserService.getTotpStatus();
        if (!isTotpVerified) {
          setIsUserAuthenticated('');
          console.log(`%c TOTP VERIFICATION FAILED`, "color: blue; font-size: 1.2em;");
        } else {
          console.log(`%c TOTP VERIFICATION SUCCEEDED`, "color: blue; font-size: 1.2em;");
        }
      }
    }
  };

  useEffect(() => {
    setSSOAuthenticated();
  }, []);

  useEffect(() => {

    if (isUserAuthenticated) {
      setTimeout(() => {
        setShowBackground(true);
      }, 2000);
    }

  }, [isUserAuthenticated])

  console.log(`%c PRIVATE ROUTE isUserAuthenticated=${isUserAuthenticated} role=${role} redirect=${window.location.pathname}${window.location.search}`, 'color: blue; font-size: 1.2em;')
  console.log(`%c isUserAuthenticated=${isUserAuthenticated} loginRoute=${!window.location.pathname?.includes(ROUTES.LOGIN)} isUserAuthorized=${isUserAuthorized(role, window.location)}`, 'color: blue; font-size: 1.2em;');
  debugger;

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthenticated && !window.location.pathname?.includes(ROUTES.LOGIN) ? (
          isUserAuthorized(role, props.location) ? (
            <Suspense fallback={<LoadingAnimation compact={true} />}>
              <Component {...props} />
            </Suspense>
          ) :
            showBackground ? (
              <main className="relative isolate min-h-full">
                <img
                  src="https://images.unsplash.com/photo-1614064745737-b70184a320d8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
                  alt=""
                  className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
                />
                <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
                  <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Not Authorized / Page not Found </h1>
                  <p className="mt-4 text-base text-white/70 sm:mt-6">We're sorry, but the page you're looking for cannot be found. It's possible that the page may have been removed or you may not have the necessary authorization to access it.</p>
                  <div className="mt-10 flex justify-center">
                    <a href="/" className="text-sm font-semibold leading-7 text-white">
                      <span aria-hidden="true">&larr;</span> Back to home
                    </a>
                  </div>
                </div>
                <p className="absolute inset-x-0 bottom-2 text-center font-light w-full py-2 px-4 text-xs leading-5 text-gray-100 drop-shadow-md hover:scale-110 transition-transform hover:font-normal">&copy;2023 sonet.io, Inc. All rights reserved.</p>
              </main>) : null
        ) : (
          history.push(`${ROUTES.LOGIN}?redirect=${window.location.pathname}${window.location.search}`)
        )
      }
    />
  );
};


const mapStateToProps = (state: any) => ({
  token: state?.token
});

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
